import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ExerciseList from "../components/ExerciseList.tsx";
import Exercise from "../components/Exercise.tsx";

interface ListExercisesProps {
  setExercise: React.Dispatch<React.SetStateAction<Exercise>>;
}

const ListExercisesPage: React.FC<ListExercisesProps> = ({ setExercise }) => {
  const navigate = useNavigate();

  // Use state to bring in the data
  const [exercises, setExercises] = useState<Exercise[]>([]);

  // RETRIEVE the list of exercises
  const loadExercises = async () => {
    try {
    const response = await fetch("/api/exercises");
    const exercises = await response.json();
    setExercises(exercises);
    } catch(error) {
      console.log("Failed to load exercises. ", error);
    }
  };

  // UPDATE an exercise
  const onEditExercise = async (exercise: Exercise) => {
    setExercise(exercise);
    loadExercises();
    navigate(`/exercises`);
  };

  // DELETE an exercise
  const onDeleteExercise = async (_id: string) => {
    const response = await fetch(`/exercises/${_id}`, { method: "DELETE" });
      if (response.status === 204) {
        await loadExercises();
        navigate("/exercises");
      } else {
        alert(
          `Failed to delete exercise with _id = ${_id}, status code = ${response.status}`
        );
      }
  };

  // LOAD the exercises
  useEffect(() => {
    loadExercises();
  }, []);

  // DISPLAY the exercises
  return (
    <>
      <article>
        <h2>Your workout plan</h2>
        <p>View, revise, and delete exercises for your workout plan.</p>
        <ExerciseList
          exercises={exercises}
          onEdit={onEditExercise}
          onDelete={onDeleteExercise}
        />
      </article>
    </>
  );
};

export default ListExercisesPage;
