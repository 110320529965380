import React, { useState, useEffect, DragEvent} from "react";
import { Link, useNavigate } from "react-router-dom";
import Exercise, { ExerciseProps, EditHandler, DeleteHandler } from "./Exercise.tsx";
import SuggestExercise from "./SuggestExercise.tsx";


type Props = {
  onEdit: EditHandler,
  onDelete: DeleteHandler,
  exercises: ExerciseProps[],
};

function ExerciseList({ exercises, onDelete, onEdit }: Props) {

  const handleSuggestedExercise = async (exercise: Exercise) => {
    let date = new Date().toISOString();
    const newExercise = {
      name: exercise.name,
      equipment: exercise.equipment,
      primaryMuscles: exercise.primaryMuscles,
      instructions: exercise.instructions,
      images: exercise.images,
      date: date
    };
    try {
      const response = await fetch("/exercises", {
        method: "POST",
        body: JSON.stringify(newExercise),
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 201) {
        alert("Successfully added the exercise!");
      } else {
        alert(`Failed to add exercise, status code = ${response.status}`);
      }
    } catch (error) {
      // console.log(error);
    }
  };

// Drag and drop functions adapted from URL: https://dev.to/florantara/creating-a-drag-and-drop-list-with-react-hooks-4c0
// Author: Flor Antara
  const onDragStart = (event: DragEvent) => {
    const initialPosition = Number(event.currentTarget.dataset.position);
    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: initialPosition,
      isDragging: true,
      originalOrder: list,
    });
    // weird Firefox bug avoidance
    event.dataTransfer.setData("text/html", "");
  };

  const onDragOver = (event: DragEvent) => {
    event.preventDefault();
    let newList = dragAndDrop.originalOrder;
    const draggedFrom = dragAndDrop.draggedFrom;
    const draggedTo = Number(event.currentTarget.dataset.position);
    const itemDragged = newList[draggedFrom];
    const remainingItems = newList.filter((item, index) => index !== draggedFrom);
    // this is the list after reordering
    newList = [
      ...remainingItems.slice(0, draggedTo),
      itemDragged,
      ...remainingItems.slice(draggedTo),
    ];
    // only update if the list item was really moved
    if (draggedTo !== dragAndDrop.draggedTo) {
      setDragAndDrop({
        ...dragAndDrop,
        updatedOrder: newList,
        draggedTo: draggedTo,
      });
    }
  };

  const onDrop = () => {
    setList(dragAndDrop.updatedOrder);
    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: null,
      draggedTo: null,
      isDragging: false,
    });
  };

  const initialListState = {
    draggedFrom: null,
    draggedTo: null,
    isDragging: false,
    originalOrder: [],
    updatedOrder: [],
  };

  const [dragAndDrop, setDragAndDrop] = useState(initialListState);
  const [list, setList] = useState<ExerciseProps[]>([]);
  useEffect(() => {
    setList(exercises);
  }, [exercises]);

  return (
    <>
      <article id="exercises">
          <ul class="cards">
          {list.length > 0 ? (
          list.map((exercise, index) => (
          <li
            key={exercise._id}
            className="grabbable"
            draggable="true"
            onDragStart={onDragStart}
            onDragOver={onDragOver}
            onDrop={onDrop}
            data-position={index}>
            <Exercise
              exercise={exercise}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          </li>
          ))) : (<li>No exercises found</li>)}
          </ul>
        <section>
          <SuggestExercise
            onExerciseSuggestion={handleSuggestedExercise}
         />
        </section>
      </article>

    </>
  );
}

export default ExerciseList;
