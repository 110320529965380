import React, { useEffect, useState, EditHandler, DeleteHandler } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Exercise, { ExerciseProps } from "../components/Exercise.tsx";

interface ViewExerciseProps {
  setExercise: React.Dispatch<React.SetStateAction<ExerciseProps[]>>;
}

export const ViewExercisePage: React.FC<ViewExerciseProps> = ({ setExercise }) => {

  const [exercise, setExerciseState] = useState<ExerciseProps | null>(null);
  const { exerciseId } = useParams<{ exerciseId: string }>();
  const navigate = useNavigate();

  // UPDATE an exercise
  const onEditExercise = async (exercise: ExerciseProps) => {
    navigate(`/edit-exercise/${exercise._id}`, { state: { exercise }});
  };

  // DELETE an exercise
  const onDeleteExercise = async (_id: string) => {
    const response = await fetch(`/exercises/${_id}`, { method: "DELETE" });
    if (response.status === 204) {
      navigate('/exercises');
    } else {
      alert(
        `Failed to delete exercise with _id = ${_id}, status code = ${response.status}`
      );
    }
  };


  const loadExerciseById = async (exerciseId: string) => {
    try {
      const response = await fetch(`/api/exercises/${exerciseId}`);
      if (!response.ok) {
        throw new Error(`Failed to load exercise with ID ${exerciseId}`);
      }
      const exerciseData = await response.json();
      setExerciseState(exerciseData);
    } catch (error) {
      console.error(`Failed to load exercise with ID ${exerciseId}.`, error);
    }
  };

  useEffect(() => {
    if (exerciseId){
    loadExerciseById(exerciseId);
    }
  }, [exerciseId]);

  return (
      <article>
            {exercise ? (
            <Exercise
              exercise={exercise}
              onEdit={onEditExercise}
              onDelete={onDeleteExercise}
            />
            ) : null}
      </article>
  );
};

export default ViewExercisePage;
