import React from "react";
import { Link } from "react-router-dom";
import { BiHomeAlt, BiListUl, BiSolidPlusSquare } from "react-icons/bi";


const Nav: React.FC = () => {
  return (
    <nav>
      <Link to="/">
        <BiHomeAlt aria-hidden="true" title="Home" />Home</Link>
      <Link to="/exercises">
        <BiListUl aria-hidden="true" title="List" />
      All Exercises</Link>
      <Link to="/add-exercise">
        <BiSolidPlusSquare aria-hidden="true" title="Add" />Add Exercise to Plan
      </Link>
    </nav>
  );
}

export default Nav;
