import React from "react";
import ReactDOM from "react-dom";
import "./App.css";
import App from "./App.tsx";

const rootElement: HTMLElement | null = document.getElementById("root");

if (rootElement) {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    rootElement
  );
} else {
  throw new Error("Root element, ID 'root' not found in this document.");
}
