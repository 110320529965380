// Import dependencies
import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";

// Import Components, styles, media
import "./App.css";
import Nav from "./components/Nav.tsx";
import Exercise,  { ExerciseProps } from "./components/Exercise.tsx";

// Import Pages
import AddExercisePage from "./pages/AddExercisePage.tsx";
import ListExercisesPage from "./pages/ListExercisesPage.tsx";
import ViewExercisePage from "./pages/ViewExercisePage.tsx";
import HomePage from "./pages/HomePage.tsx";

// Define the function that renders the content in routes using State.
const App: React.FC = () => {
  const [exercise, setExercise] = useState([] as ExerciseProps[]);

  return (
    <>
      <Router>
        <header>
          <h1>Work It Out</h1>
          <p>It's time to work it out. Let us help motivate you.</p>
        </header>
        <Nav />

        <main>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/add-exercise" element={<AddExercisePage />} />
            <Route path="/exercises/:exerciseId" element={<ViewExercisePage setExercise={setExercise} />} />
            <Route path="/exercises" element={<ListExercisesPage setExercise={setExercise} />} />
          </Routes>
        </main>

        <footer>
<p>Exercise images and content provided by <Link to="https://github.com/yuhonas/free-exercise-db">Yuhonas/free-exercise-db</Link>, an Open Public Domain Dataset.</p>
<p>
You should always consult your physician or other healthcare provider before starting an exercise program. Before starting any of the suggested exercises, consider your current level of fitness and whether you are able to perform the exercise.
</p><p>
You assume full responsibility for any and all injuries, losses and damages that you incur while using. You hereby waive all claims against this application's developers, maintainers, and promoters.</p>
        </footer>
      </Router>
    </>
  );
}

export default App;
