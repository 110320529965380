import React from "react";
import { Link } from "react-router-dom";

interface HomePageProps {
}

const HomePage: React.FC<HomePageProps> = () => {

  return (
    <>
      <article>
        <h2>Welcome to your fitness plan.</h2>
        <p>Here is where you can <Link to="/add-exercise">add</Link>, <Link to="/exercises">revise</Link>, or <Link to="/exercises">remove</Link> entries in your <Link to="/exercises">fitness plan</Link>.
        </p>
      </article>
    </>
  );
};

export default HomePage;
