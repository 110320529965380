const suggestExercise = async () => {
  const API =
    "https://raw.githubusercontent.com/yuhonas/free-exercise-db/main/dist/exercises.json";

  try {
    const response = await fetch(API);

    if (!response.ok) {
      console.error("Response not OK:", response.statusText);
      alert("Can't fetch a new exercise.");
      return null;
    }

    const data = await response.text();
    const exercises = JSON.parse(data);
    const randomIndex = Math.floor(Math.random() * exercises.length);
    const exercise = exercises[randomIndex];
    return exercise;
  } catch (error) {
    console.error("Error during fetch:", error.message);
    throw new Error("Can't suggest exercise. Error: " + error.message);
  }
};
module.exports = { suggestExercise };
