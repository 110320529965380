import React, { useState } from "react";
import { BiBulb, BiSolidPlusSquare } from "react-icons/bi";
import { suggestExercise } from "../services/services";
import { Exercise } from "./Exercise";

interface SuggestExerciseProps {
  onExerciseSuggestion: (exercise: Exercise | null) => void;
}

const SuggestExercise: React.FC<SuggestExerciseProps> = ({
  onExerciseSuggestion,
}) => {
  const [exercise, setExercise] = useState<Exercise | null>(null);

  const handleNewSave = () => {
    onExerciseSuggestion(exercise);
    window.location.reload();
  };

  const handleClick = async () => {
    try {
      const newExercise = await suggestExercise();
      setExercise(newExercise);
    } catch (error) {
      console.error("Error suggesting exercise");
    }
  };

  return (
  <>
  <button onClick={handleClick}>
        <BiBulb aria-label="Let's suggest an exercise for you" />
        Let's suggest an exercise for you
  </button>
  {exercise && (
    <>
      <section className="result">
        Exercise: {exercise.name}
        <button title="Add this exercise to plan" onClick={handleNewSave}>
          <BiSolidPlusSquare aria-hidden="true" title="Add" /> Add this exercise to plan
        </button>
      </section>
    </>
  )}
  </>
);

};

export default SuggestExercise;
