import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BiSolidPlusSquare } from "react-icons/bi";
import { useForm } from "react-hook-form";
import SuggestExercise from "../components/SuggestExercise.tsx";


export const AddExercisePage = () => {
  const navigate = useNavigate();
  const { register,
          handleSubmit,
          setValue,
          formState: { errors },
     } = useForm();

  const handlePrimaryMusclesInput = (input) => {
    if (input.trim() !== "") {
      const muscles = input
        .split(",")
        .filter((muscle) => muscle);
      setValue("primaryMuscles", muscles);
    }
  };

  const handleInstructionsInput = (input) => {
    if (input.trim() !== "") {
      const instructions = input
        .split(",")
        .filter((instruction) => instruction);
      setValue("instructions", instructions);
    }
  };

  const handleSuggestedExercise = async (exercise: Exercise) => {
    let date = new Date().toISOString();
    const newExercise = {
      name: exercise.name,
      equipment: exercise.equipment,
      primaryMuscles: exercise.primaryMuscles,
      instructions: exercise.instructions,
      images: exercise.images,
      date: date
    };
    try {
      const response = await fetch("/exercises", {
        method: "POST",
        body: JSON.stringify(newExercise),
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 201) {
        alert("Successfully added the exercise!");
        navigate("/exercises");
      } else {
        alert(`Failed to add exercise, status code = ${response.status}`);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const addExercise = async (data) => {
    const response = await fetch("/exercises", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 201) {
      alert("Successfully added the exercise!");
      navigate("/exercises");
    } else {
      alert(`Failed to add exercise, status code = ${response.status}`);
    }
  };

  return (
    <>
      <article>
        <h2>Add to your workout plan</h2>
        <p>Retrieve an exercise from the database, or add your own.</p>
<section>
          <SuggestExercise
            onExerciseSuggestion={handleSuggestedExercise}
         />
        </section>

        <form
          onSubmit={handleSubmit(addExercise)}
        >
          <fieldset>
            <legend>Add Exercise</legend>
            <label htmlFor="name">Exercise name</label>
            <input
              required
              autocomplete="off"
              type="text"
              id="name"
              {...register("name", { required: "Name is required" })}
              aria-describedby="name-hint"
          />
          {errors.name && <div className="error">{errors.name.message}</div>}
            <span className="form-hint" id="name-hint">
              For example: squat
            </span>

            <label htmlFor="equipment">Equipment</label>
            <input type="text" id="equipment" {...register("equipment")} />

            <label htmlFor="primaryMuscles">Primary Muscles</label>
            <p className="hint">
              Enter one or more muscles, separated by commas. For example,{" "}
              <strong>back, shoulders</strong>.
            </p>
            <input
            type="text"
            id="primaryMuscles"
            {...register("primaryMuscles")}
            onChange={(e) => handlePrimaryMusclesInput(e.target.value)}
          />

            <label htmlFor="instructions">Instructions</label>
            <p className="hint">
              Enter one or more sentences describing how to do this exercise,
              separated by commas. For example,{" "}
              <strong>
                "Lie down on the floor and secure your feet. Your legs should be
                bent at the knees.", "Place your hands behind or to the side of
                your head."
              </strong>
              .
            </p>
             <textarea
            rows="5"
            cols="40"
            id="instructions"
            {...register("instructions")}
            onChange={(e) => handleInstructionsInput(e.target.value)}
          />
            <label htmlFor="date">Date of exercise</label>
            <input
              id="date"
              type="date"
              required
              {...register("date", {
                required: "Date is required",
                validate: (value) => {
                  return !!value || "Date is required";
                },
              })}
            />
            {errors.date && <div className="error">{errors.date.message}</div>}
            <button type="submit" id="submit">
              Add to plan
            </button>
          </fieldset>
        </form>
      </article>
    </>
  );
};

export default AddExercisePage;
