import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { BiTrashAlt, BiEditAlt, BiSave, BiWalk } from "react-icons/bi";

export interface Exercise {
  name: string;
  equipment: string;
  primaryMuscles: string[];
  instructions: string[];
  images: string[];
  date: Date;
  _id?: string;
}

export interface ExerciseProps {
  onEdit: (exercise: Exercise) => void;
  onDelete: (id: string) => void;
  exercise: Exercise;
}

const Exercise: React.FC<ExerciseProps> = (props) => {
  const { exercise, onEdit, onDelete } = props;
  // toggle between display and editing form
  const [isEditing, setIsEditing] = useState(false);
  const [isOpen, setIsOpen] = useState(false); // state of details toggle

// editedExercise is copy with particular care for array values
  const [editedExercise, setEditedExercise] = useState<Exercise>({
    ...exercise,
    primaryMuscles: Array.isArray(exercise.primaryMuscles) ? exercise.primaryMuscles : [],
    instructions: Array.isArray(exercise.instructions) ? exercise.instructions : [],
    images: Array.isArray(exercise.images) ? exercise.images : [],
  });

  const formatDate = (storedDateString: string, format: string): string => {
    const storedDate = new Date(storedDateString);
    const dateOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    return storedDate.toLocaleDateString(format, dateOptions);
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
    if (!isEditing){
      setIsOpen(true);
    }
  };

  const handleDelete = (id: string) => {
    alert("Successfully removed this exercise!");
    onDelete(id);
  };

  const handleInputChange = (
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
) => {
  // get the name of the event target, and its value
  const { name, value } = e.target;

  // Prepare a temporary state object
  // `Partial` is a TypeScript object that has all props optional
  let newState: Partial<Exercise> = {};

  // Handle array values separately. Allow arrays to be empty.
  if (name === "primaryMuscles" || name === "images") {
    const arrayData = value ? value.split(",").map(datum => datum.trim()) : [];
    newState = {...editedExercise, [name]: arrayData};
  } else if (name === "instructions") {
    const arrayData = value ? value.split(",") : [];
    newState = {...editedExercise, [name]: arrayData};
  } else {
    newState = { ...editedExercise, [name]: value };
  }

  // Update the state
  setEditedExercise(newState as Exercise);
};


  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedExercise({ ...editedExercise, date: new Date(e.target.value) });
  };

  const handleEditSave = async () => {
    try {
      const response = await fetch(`/exercises/${exercise._id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(editedExercise),
      });

      if (response.status === 200) {
        const updatedExercise = await response.json();
        alert("Successfully edited this exercise!");
        onEdit(updatedExercise); // will notify ExerciseList of the update
        setIsEditing(false); // Hide editing form
      } else {
        alert(
          `Failed to update exercise. Status ${response.status}. ${errMessage.Error}`
        );
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      {exercise._id && (
        <details open={isEditing || isOpen} onToggle={() => setIsOpen(!isEditing) && !isOpen}>
          <summary>
            <div className="flex-container">
            {isEditing ? (
              <>
                <label htmlFor="name">Exercise name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={editedExercise.name}
                  onChange={handleInputChange}
                  required
                  autocomplete="off"
                />
              </>
            ) : (
              exercise.name
            )}
                <span className="icon">
                {exercise.images.length > 0 ? ( <img src={`https://raw.githubusercontent.com/yuhonas/free-exercise-db/main/exercises/${exercise.images[0]}`} alt={exercise.name} />
    ) : ( <BiWalk /> )}
              </span>
            </div>
          </summary>
          <article>
            <ul>
              {exercise.equipment && (
                <li>
                  {isEditing ? (
                    <>
                      <label htmlFor="equipment">Equipment</label>
                      <input
                        type="text"
                        name="equipment"
                        id="equipment"
                        value={editedExercise.equipment}
                        onChange={handleInputChange}
                      />
                    </>
                  ) : (
                    <>Equipment:&nbsp; {exercise.equipment}</>
                  )}
                </li>
              )}
              {exercise.primaryMuscles.length > 0 && (
                <li>
                  {isEditing ? (
                    <>
                      <label htmlFor="primaryMuscles">Primary Muscles</label>
                      <p className="hint">
                        Enter one or more muscles, separated by commas. For
                        example, <strong>back, shoulders</strong>.
                      </p>
                      <input
                        type="text"
                        value={editedExercise.primaryMuscles.join(", ")}
                        onChange={handleInputChange}
                        id="primaryMuscles"
                        name="primaryMuscles"
                      />
                    </>
                  ) : (
                    <>
                      Primary muscles:&nbsp;
                      {exercise.primaryMuscles.map((muscle, i) => (
                        <span className="enum" key={i}>
                          {muscle}
                        </span>
                      ))}
                    </>
                  )}
                </li>
              )}
              {exercise.instructions.length > 0 && (
                <li>
                  {isEditing ? (
                    <>
                      <label htmlFor="instructions">Instructions</label>
                      <p className="hint">
                        Enter one or more sentences describing how to do this
                        exercise, separated by commas. For example,{" "}
                        <strong>
                          "Lie down on the floor and secure your feet. Your legs
                          should be bent at the knees.", "Place your hands
                          behind or to the side of your head."
                        </strong>
                        .
                      </p>
                      <textarea
                        name="instructions"
                        id="instructions"
                        value={editedExercise.instructions.join(",")}
                        onChange={handleInputChange}
                        rows="5"
                        cols="40"
                      />
                    </>
                  ) : (
                    <>
                      How to do it:
                      {exercise.instructions.map((instruction, i) => (
                        <p key={i}>{instruction}</p>
                      ))}
                    </>
                  )}
                </li>
              )}
              {exercise.images.length > 0 && (
                <li>
                  {isEditing ? (
                    <>
                      <label htmlFor="images">Images</label>
                      <p className="hint">
                        Enter one or more URLs for images showing this exercise separated by commas. For example,
                        <strong>http://example.com/squat.jpg, http://example.com/squat2.jpg</strong>.
                      </p>
                      <textarea
                        name="images"
                        id="images"
                        value={Array.isArray(editedExercise.images) ? editedExercise.images.join(",") : ""}
                        onChange={handleInputChange}
                        rows="3"
                        cols="40"
                      />
                    </>
                  ) : (
                    <>
                      Images:
                      {exercise.images.map((image, i) => (
                        <img key={i} src={`https://raw.githubusercontent.com/yuhonas/free-exercise-db/main/exercises/${image}`} alt={exercise.instructions[i]} />
                      ))}
                    </>
                  )}
                </li>
              )}
              <li>
                {isEditing ? (
                  <>
                    <label htmlFor="date">Date of exercise</label>
                    <input
                      type="date"
                      name="date"
                      id="date"
                      value={formatDate(editedExercise.date, "sv-SE")}
                      onChange={handleDateChange}
                    />
                  </>
                ) : (
                  formatDate(exercise.date, "en-US")
                )}
              </li>
            </ul>
            <ul className="actions">
              <li>
                {isEditing ? (
                  <button title="Save changes" onClick={handleEditSave}>
                    <BiSave aria-label="Save" />
                  </button>
                ) : (
                  <button title="Edit" onClick={handleEditToggle}>
                    <BiEditAlt aria-label="Edit" />
                  </button>
                )}
              </li>
              <li>
                <button
                  title="Remove from plan"
                  onClick={() => handleDelete(exercise._id!)}
                >
                  <BiTrashAlt aria-label="Remove from plan" />
                </button>
              </li>
            </ul>
          </article>
        </details>
      )}
    </>
  );
};

export default Exercise;
